import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {socket} from "./others/SocketInstance";
import Loading from "./others/Loading";
import ReactPaginate from "react-paginate";
import {Button, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";

function Events() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);
    const navigate = useNavigate();

    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [deleteDataId, setDeleteDataId] = useState(null);
    const [deleteDataName, setDeleteDataName] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [statusDataId, setStatusDataId] = useState(null);
    const [statusDataName, setStatusDataName] = useState(null);
    const [showStatusModal, setShowStatusModal] = useState(false);

    const pageCount = Math.ceil(total / pageSize);

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    const userRole = getUserRole(token);

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/events?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            event_name: formData.get("event_name"),
            event_type: formData.get("event_type"),
            event_date: formData.get("event_date"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/event`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            id: formData.get('id'),
            event_name: formData.get("event_name"),
            event_type: formData.get("event_type"),
            event_date: formData.get("event_date"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/event`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
            })
    }

    function confirmDeleteData(id, event_name) {
        setDeleteDataId(id);
        setDeleteDataName(`${event_name}`);
        setShowDeleteModal(true);
    }

    function confirmChangeStatus(id, status) {
        setStatusDataId(id);
        setStatusDataName(`${status}`);
        setShowStatusModal(true);
    }

    function handleDeleteData(id) {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete/event/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                const updatedData = data.filter(item => item.id !== id);
                setData(updatedData);
                toast.success('Event removed successfully.');
            })
            .catch(error => {
                console.error(error);
                toast.error('An error occurred while deleting data.');
            });
    }

    function handleChangeStatusData(id) {
        axios.put(`${process.env.REACT_APP_API_URL}/change-status/event/${id}`, {},
            {
                headers: {
                    'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
                }
            }
        )
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    }

    useEffect(() => {
        socket.on('event_added', (newData) => {
            setData(prevData => [newData, ...prevData]);
        });
        socket.on('event_updated', (newData) => {
            setData((prevData) => prevData.map(data => data.id === newData.id ? newData : data))
        });
        socket.on('event_updated_status', (newData) => {
            setData((prevData) => prevData.map(data => data.id === newData.id ? newData : data))
        });


        return () => {
            socket.off('event_added');
            socket.off('event_updated');
            socket.off('event_updated_status');
        };
    }, []);


    function handleAdminMatches(id, event_name) {
        const encodedEventName = encodeURIComponent(event_name);
        navigate(`/portal/event/${id}/${encodedEventName}`);
    }

    function handleTellerMatches(id, event_name) {
        const encodedEventName = encodeURIComponent(event_name);
        navigate(`/portal/event/bets/${id}/${encodedEventName}`);
    }

    if (isLoading) {
        return (<Loading/>);
    }

    return (<>
        <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Events</h3>
        <div className="card shadow border-primary mb-3 mx-4">
            <div className="card-header">
                <p className="text-primary m-0 fw-bold d-inline">Events Information</p>
                {userRole === 1 || userRole === 2 ? (
                    <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                        setAddModal(true)
                    }}>Add New Event
                    </button>
                ) : null}
            </div>
            <div className="card-body">
                <div className="row g-3">
                    <div className='col-md-11'>
                        <input type="text" className="form-control" placeholder="Search Anything Here!"
                               aria-label="Search"
                               aria-describedby="basic-addon2" value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <div className="table-responsive table mt-2" id="dataTable" role="grid"
                     aria-describedby="dataTable_info">
                    <table className="table my-0" id="dataTable">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                        {data.length === 0 ? (
                            <tr>
                                <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                            </tr>
                        ) : (
                            data
                                .filter(data => userRole !== 3 || data.status === 'ACTIVE') // Only show active events if userRole is 3
                                .map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.event_name}</td>
                                        <td>{data.event_type}</td>
                                        <td>{data.event_date}</td>
                                        <td>{data.status}</td>
                                        <td>
                                            {(userRole === 1 || userRole === 2 || userRole === 4) && (
                                                <>
                                                    <button className="btn btn-success btn-sm mx-1 my-1"
                                                            onClick={() => handleAdminMatches(data.id, data.event_name)}>
                                                        Admin Matches
                                                    </button>
                                                </>
                                            )}
                                            {(userRole === 1 || userRole === 3 || userRole === 4 || userRole === 2) && (
                                                <button className="btn btn-success btn-sm mx-1 my-1"
                                                        onClick={() => handleTellerMatches(data.id, data.event_name)}>
                                                    Teller Matches
                                                </button>
                                            )}
                                            {(userRole === 1 || userRole === 2) && (
                                                <button className="btn btn-warning btn-sm mx-1 my-1" onClick={() => {
                                                    setFormData({
                                                        id: data.id,
                                                        event_name: data.event_name,
                                                        event_type: data.event_type,
                                                        event_date: data.event_date,
                                                    });
                                                    setEditModal(true)
                                                }}>
                                                    <i className='fas fa-edit'></i>
                                                </button>
                                            )}
                                            {(userRole === 1 || userRole === 2) && (
                                                <button className="btn btn-danger btn-sm mx-1 my-1"
                                                        onClick={() => confirmDeleteData(data.id, data.event_name)}>
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            )}
                                            {(userRole === 1 || userRole === 2) && (
                                                <button className="btn btn-danger btn-sm mx-1 my-1"
                                                        onClick={() => confirmChangeStatus(data.id, data.status)}>
                                                    Change Status
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                        )}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination justify-content-center mt-3"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    disabledClassName="disabled"
                />
            </div>
        </div>

        <Modal
            size="lg"
            show={showAddModal}
            onHide={() => setAddModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Event
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmAddData}>
                    <label className="form-label">Event Name</label>
                    <input className="form-control" type="text" name="event_name" id="event_name"
                           required/>
                    <label className="form-label">Event Date</label>
                    <input className="form-control" type="date" name="event_date" id="event_date"
                           required/>
                    <label className="form-label">Event Type</label>
                    <select className="form-select" aria-label="Default select example" name="event_type"
                            id="event_type" required>
                        <option value="" selected disabled></option>
                        <option value="LASTTWO">LAST TWO</option>
                        <option value="SWERTRES">SWERTRES</option>
                    </select>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Event Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Event Name:</strong> {formData.event_name}</p>
                <p><strong>Event Date:</strong> {formData.event_date}</p>
                <p><strong>Event Type:</strong> {formData.event_type}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setAddConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setAddConfirmModal(false);
                    handleAddData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="lg"
            show={showEditModal}
            onHide={() => setEditModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Event
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={confirmEditData}>
                    <input className="form-control" type="number" name="id" id="id"
                           value={formData.id}
                           required readOnly hidden/>
                    <label className="form-label">Event Name</label>
                    <input className="form-control" type="text" name="event_name" id="event_name"
                           value={formData.event_name}
                           onChange={(e) => setFormData({...formData, event_name: e.target.value})}
                           required/>
                    <label className="form-label">Event Date</label>
                    <input className="form-control" type="date" name="event_date" id="event_date"
                           value={formData.event_date}
                           onChange={(e) => setFormData({...formData, event_date: e.target.value})}
                           required/>
                    <label className="form-label">Event Type</label>
                    <select className="form-select" aria-label="Default select example" name="event_type"
                            id="event_type" required
                            value={formData.event_type}
                            onChange={(e) => setFormData({...formData, event_type: e.target.value})}>
                        <option value="" selected disabled></option>
                        <option value="LASTTWO">LAST TWO</option>
                        <option value="SWERTRES">SWERTRES</option>
                    </select>
                    <div className="align-content-end">
                        <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                        >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

        <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Confirm Event Edited Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Event Name:</strong> {formData.event_name}</p>
                <p><strong>Event Date:</strong> {formData.event_date}</p>
                <p><strong>Event Type:</strong> {formData.event_type}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setEditConfirmModal(false);
                    setModifying(false);
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    setEditConfirmModal(false);
                    handleEditData();
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Delete Event: {deleteDataName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete Event: {deleteDataName}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    handleDeleteData(deleteDataId);
                    setShowDeleteModal(false);
                }}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Current Status: {statusDataName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to Change the status of this Event?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    handleChangeStatusData(statusDataId);
                    setShowStatusModal(false);
                }}>
                    Change
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default Events;