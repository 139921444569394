import './App.css';
import {ToastContainer} from "react-toastify";
import {Route, Routes} from "react-router-dom";
import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Body from "./components/pages/layout/Body";
import Events from "./components/pages/Events";
import NotFound from "./components/pages/others/NotFound";
import AdminEvent from "./components/pages/singlePages/AdminEvent";
import TellerEvent from "./components/pages/singlePages/TellerEvent";
import Settings from "./components/pages/Settings";
import PrintReceipt from "./components/pages/printPages/PrintReceipt";
import PrintClaim from "./components/pages/printPages/PrintClaim";


function App() {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <Routes>
                <Route path='/' element={<Login/>}/>
                <Route path='/portal/dashboard'
                       element={<Body element={<Dashboard/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/events'
                       element={<Body element={<Events/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/event/:id/:event_name'
                       element={<Body element={<AdminEvent/>} allowedRoles={[1, 2, 4]}/>}/>
                <Route path='/portal/event/bets/:id/:event_name'
                       element={<Body element={<TellerEvent/>} allowedRoles={[1, 3, 4]}/>}/>
                <Route path='/portal/settings'
                       element={<Body element={<Settings/>} allowedRoles={[1, 4]}/>}/>
                <Route path='/portal/receipt/print/:id'
                       element={<Body element={<PrintReceipt/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path='/portal/receipt-claim/print/:id'
                       element={<Body element={<PrintClaim/>} allowedRoles={[1, 2, 3, 4]}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>

        </>
    );
}

export default App;
