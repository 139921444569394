import React, {useEffect, useState} from "react";
import {socket} from "./others/SocketInstance";
import Loading from "./others/Loading";
import {toast} from "react-toastify";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {PatternFormat} from "react-number-format";
import ReactPaginate from "react-paginate";

function Settings() {
    const token = localStorage.getItem("token");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true)
    const [isLoadingTwo, setLoadingTwo] = useState(true)
    const [data, setData] = useState([])
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    const [tellers, setTellers] = useState([])

    const [showAddPasswordModal, setAddPasswordModal] = useState(false);
    const [showAddPasswordConfrimModal, setAddPasswordConfirmModal] = useState(false);
    const [password, setPassword] = useState({});

    const [accountType, setAccountType] = useState('');

    const handleAccountTypeChange = (e) => {
        setAccountType(e.target.value);
    };

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    const userRole = getUserRole(token);

    const roleNames = {
        1: "Super Admin",
        2: "Operator",
        3: "Teller",
        4: "Admin/Viewer",
    };

    const pageCount = Math.ceil(total / pageSize);

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/users?page=${pageNumber + 1}&page_size=${pageSize}&search=${searchTerm}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
                setTellers(response.data.data_teller)
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pageNumber, pageSize, searchTerm, token])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/password`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setPassword(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoadingTwo(false)
            })
    }, [token])


    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            first_name: formData.get("first_name"),
            middle_initial: formData.get("middle_initial"),
            last_name: formData.get("last_name"),
            contact_number: formData.get("contact_number"),
            email: formData.get("email"),
            password: formData.get("password"),
            account_type: formData.get("account_type"),
            username: formData.get("username"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/user`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            id: formData.get('id'),
            first_name: formData.get("first_name"),
            middle_initial: formData.get("middle_initial"),
            last_name: formData.get("last_name"),
            contact_number: formData.get("contact_number"),
            password: formData.get("password"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/user`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
            })
    }

    function confirmAddPasswordData(event) {
        event.preventDefault();
        setModifying(true);

        const formData = new FormData(event.target);
        const oldPassword = formData.get("old_password");
        const newPassword = formData.get("new_password");
        const repeatPassword = formData.get("rep_password");

        // Check if new password and repeat password match
        if (newPassword !== repeatPassword) {
            toast.error('New password and repeat password do not match');
            setModifying(false); // Reset modifying state
            return; // Prevent further execution
        }

        const data = {
            old_password: oldPassword,
            new_password: newPassword,
        };
        setFormData(data);
        setAddPasswordConfirmModal(true);
    }

    function handleAddPasswordData() {
        axios.post(`${process.env.REACT_APP_API_URL}/add/password`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddPasswordModal(false)
            })
    }

    useEffect(() => {
        socket.on('user_added', (newData) => {
            setData(prevData => [newData, ...prevData]);
        });
        socket.on('user_updated', (newData) => {
            setData((prevData) => prevData.map(data => data.id === newData.id ? newData : data))
        });
        socket.on('password_added', (newData) => {
            setPassword(newData);
        });
        socket.on('teller_added', (newData) => {
            setTellers(prevData => [newData, ...prevData]);
        });


        return () => {
            socket.off('user_added');
            socket.off('user_updated');
            socket.off('password_added');
            socket.off('teller_added');
        };
    }, []);

    if (isLoading || isLoadingTwo) {
        return (
            <Loading/>
        );
    }
    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Settings</h3>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Users Information</p>
                    {userRole === 1 ? (
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddModal(true)
                        }}>Add New User
                        </button>
                    ) : null}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md-11'>
                            <input type="text" className="form-control" placeholder="Search Anything Here!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md'>
                            <select className="form-control" value={pageSize} onChange={e => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Middle Initial</th>
                                <th>Last name</th>
                                <th>Contact Number</th>
                                <th>Email</th>
                                <th>Account Type</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>
                            ) : (
                                data.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.firstname}</td>
                                        <td>{data.middlename}</td>
                                        <td>{data.lastname}</td>
                                        <td>{data.contact_num}</td>
                                        <td>{data.email}</td>
                                        <td>{data.user_accounts.account_type_name}</td>
                                        <td>
                                            {userRole === 1 ? (
                                                <button className="btn btn-warning btn-sm" onClick={() => {
                                                    setFormData({
                                                        id: data.id,
                                                        first_name: data.firstname,
                                                        middle_initial: data.middlename,
                                                        last_name: data.lastname,
                                                        contact_number: data.contact_num,
                                                        account_type: data.user_accounts.account_type,
                                                    });
                                                    setEditModal(true)
                                                }}><i className='fas fa-edit'></i></button>
                                            ) : null}
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Tellers Information</p>
                </div>
                <div className="card-body">
                    <div className="row g-3">

                    </div>

                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Teller Number</th>
                                <th>Username</th>
                                <th>Email</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {tellers.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>
                            ) : (
                                tellers.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.teller_number}</td>
                                        <td>{data.username}</td>
                                        <td>{data.user.email}</td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

             {userRole === 1 ? (
                <div className="card shadow border-primary mb-3 mx-4">
                    <div className="card-header">
                        <p className="text-primary m-0 fw-bold d-inline">Password Information</p>
                        <button className="btn btn-primary text-end float-end btn-sm" onClick={() => {
                            setAddPasswordModal(true)
                        }}>Change Password
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row g-3">
                        </div>

                        <div className="table-responsive table mt-2" id="dataTable" role="grid"
                             aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                <tr>
                                    <th>Password</th>
                                </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                <tr key={password.id}>
                                    <td>{'*****************'}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : null}

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               required/>
                        <label className="form-label">Middle Initial (optional)</label>
                        <input className="form-control" type="text" name="middle_initial" id="middle_initial"
                        />
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               required/>
                        <label className="form-label">Contact Number (optional)</label>
                        <PatternFormat className="form-control" name="contact_number" id="contact_number"
                                       format="#### ### ####"
                                       allowEmptyFormatting mask="_"/>
                        <label className="form-label">Email</label>
                        <input className="form-control" type="email" name="email" id="email"
                               required/>
                        <label className="form-label">Password</label>
                        <input className="form-control" type="password" name="password" id="password"
                               required/>
                        <label className="form-label">Account Type</label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            name="account_type"
                            id="account_type"
                            required
                            value={accountType}
                            onChange={handleAccountTypeChange}
                        >
                            <option value="">Select Account Type</option>
                            <option value="1">Super Admin</option>
                            <option value="2">Operator</option>
                            <option value="3">Teller</option>
                            <option value="4">Admin/Viewer</option>
                        </select>

                        {accountType === '3' && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        name="username"
                                        required
                                    />
                                </div>
                            </>
                        )}
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>First Name:</strong> {formData.first_name}</p>
                    <p><strong>Middle Initial:</strong> {formData.middle_initial}</p>
                    <p><strong>Last Name:</strong> {formData.last_name}</p>
                    <p><strong>Contact Number:</strong> {formData.contact_number}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Password:</strong> {formData.password}</p>
                    <p><strong>Account Type:</strong> {roleNames[formData.account_type]}</p>
                    {accountType === '3' && (
                        <>
                            <p><strong>Username:</strong> {formData.username}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData}>
                        <input className="form-control" type="number" name="id" id="id"
                               value={formData.id}
                               required readOnly hidden/>
                        <label className="form-label">First Name</label>
                        <input className="form-control" type="text" name="first_name" id="first_name"
                               value={formData.first_name}
                               onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                               required/>
                        <label className="form-label">Middle Initial (optional)</label>
                        <input className="form-control" type="text" name="middle_initial" id="middle_initial"
                               value={formData.middle_initial}
                               onChange={(e) => setFormData({...formData, middle_initial: e.target.value})}
                        />
                        <label className="form-label">Last Name</label>
                        <input className="form-control" type="text" name="last_name" id="last_name"
                               value={formData.last_name}
                               onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                               required/>
                        <label className="form-label">Contact Number (optional)</label>
                        <PatternFormat className="form-control" name="contact_number" id="contact_number"
                                       value={formData.contact_number}
                                       onChange={(e) => setFormData({...formData, contact_number: e.target.value})}
                                       format="#### ### ####"
                                       allowEmptyFormatting mask="_"/>
                        <label className="form-label">Password (Leave Blank if you dont want to change password)</label>
                        <input className="form-control" type="password" name="password" id="password"
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm User Edited Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>First Name:</strong> {formData.first_name}</p>
                    <p><strong>Middle Initial:</strong> {formData.middle_initial}</p>
                    <p><strong>Last Name:</strong> {formData.last_name}</p>
                    <p><strong>Contact Number:</strong> {formData.contact_number}</p>
                    <p><strong>Password:</strong> {formData.password}</p>
                    <p><strong>Account Type:</strong> {roleNames[formData.account_type]}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                size="lg"
                show={showAddPasswordModal}
                onHide={() => setAddPasswordModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddPasswordData}>
                        <label className="form-label">Old Password</label>
                        <input className="form-control" type="password" name="old_password" id="old_password"
                               required/>
                        <label className="form-label">New Password</label>
                        <input className="form-control" type="password" name="new_password" id="new_password"
                        />
                        <label className="form-label">Repeat New Password</label>
                        <input className="form-control" type="password" name="rep_password" id="rep_password"
                               required/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddPasswordConfrimModal} onHide={() => setAddPasswordConfirmModal(false)}
                   backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Are you sure you will change the password?</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddPasswordConfirmModal(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddPasswordConfirmModal(false);
                        handleAddPasswordData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Settings;