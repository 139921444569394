import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../others/Loading";
import {ReactToPrint} from "react-to-print";
import Barcode from 'react-barcode';

function Receipt() {
    const {id} = useParams();
    const token = localStorage.getItem("token");
    const printContentRef = useRef()
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/print/receipt/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token, id])

    const handleGoBack = () => {
        navigate(-1)// Navigate back to the previous page
    }


    const convertToLocalTime = (utcDatetime) => {
        const date = new Date(utcDatetime);
        return date.toLocaleString();
    };

    console.log(data)
    const PrintContent = React.forwardRef((_, ref) => (
        <div ref={ref}>
            <div style={{width: '400px', maxWidth: '400px'}}>
                <div style={{textAlign: 'center'}}>
                    <h3>SMALL TOWN LOTTERY SYSTEM</h3>
                    <h3>Gr8Minds Maximus</h3>
                    <h4>{data?.event?.event_name}</h4>
                    <h4>{convertToLocalTime(data?.created_date)}</h4>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h4>Bet ID: {data?.id}</h4>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h4>Bet Number: {data?.bet_number}</h4>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h4>Bet Wagered: {data?.bet_wagered}</h4>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h4>TELLER # {data?.teller?.teller_number}</h4>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h4>TELLER: {data?.teller?.username}</h4>
                    </div>
                </div>
            </div>
        </div>
    ));

    if (isLoading) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <div className='m-4 text-center'>
                <button className="btn btn-warning mx-1" onClick={handleGoBack}>
                    Back
                </button>
                <ReactToPrint
                    trigger={() => <button className='btn btn-primary'>Print</button>}
                    content={() => printContentRef.current}
                />
            </div>


            <PrintContent ref={printContentRef}/>
        </>
    )
}

export default Receipt;