import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {socket} from "../others/SocketInstance";
import Loading from "../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {NumericFormat} from "react-number-format";
import ReactPaginate from "react-paginate";
import Receipt from "../printPages/Receipt";
import Claim from "../printPages/Claim";

function TellerEvent() {
    const printRef = useRef();
    const printClaimRef = useRef();
    const token = localStorage.getItem("token");
    const {id} = useParams();
    const {event_name} = useParams();
    const {fight_number} = useParams();
    const [data, setData] = useState([]);
    const [printBet, setPrintBet] = useState({});
    const [printClaimReceipt, setPrintClaimReceipt] = useState({});
    const [events, setEvents] = useState(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    const [isLoadingData, setLoadingData] = useState(true);
    const [isLoadingResults, setLoadingResults] = useState(true);
    const [isLoadingBets, setLoadingBets] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [bet_id, setBetId] = useState(0);

    const [betAmount, setBetAmount] = useState('');
    const [betAmountAfterCharge, setBetAmountAfterCharge] = useState('');

    const [teller, setTeller] = useState(null);
    const [cash, setCash] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [draw_cash, setDrawCash] = useState([]);
    const [draw_transactions, setDrawTransactions] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const [betPlacement, setBetPlacement] = useState('');

    const [pageDrawNumber, setPageDrawNumber] = useState(0);
    const [pageDrawSize, setPageDrawSize] = useState(10);
    const [draw_total, setDrawTotal] = useState(0);

    const pageDrawCount = Math.ceil(draw_total / pageDrawSize);

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [isLoadingModalOne, setIsLoadingModalOne] = useState(false);

    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldPrintClaim, setShouldPrintClaim] = useState(false);

    const [showReleaseModal, setReleaseModal] = useState(false);

    // New state variables for password and confirmation
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelPassword, setCancelPassword] = useState("");

    const [responseStatus, setResponseStatus] = useState(null);
    const handlePageDrawChange = ({selected}) => {
        setPageDrawNumber(selected);
    };

    const pageCount = Math.ceil(total / pageSize);

    const handlePageChange = ({selected}) => {
        setPageNumber(selected);
    };

    const [pageNumberBets, setPageNumberBets] = useState(0);
    const [pageSizeBets, setPageSizeBets] = useState(10);
    const [bets_total, setTotalBets] = useState(0);

    const pageCountBets = Math.ceil(bets_total / pageSizeBets);

    const handlePageChangeBets = ({selected}) => {
        setPageNumberBets(selected);
    };


    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    function getUserId(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.id;
    }

    const userRole = getUserRole(token);
    const userId = getUserId(token);

    const handleButtonClick = (id) => {
        setBetId(id);
        setShowModal(true);
    };

    const statusOrder = {
        'UNRELEASED': 0,
        'RELEASED': 1,
        'LOSS': 2,
        'CANCELLED': 3
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/teller-info`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setTeller(response.data.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoadingData(false);
            });
    }, [id, token, userId]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/teller-transaction-logs/${id}?page=${pageNumber + 1}&page_size=${pageSize}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setTransactions(response.data.data);
                setTotal(response.data.total);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoadingResults(false);
            });
    }, [id, pageNumber, pageSize, token]);


    useEffect(() => {
        setSearchLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/bets/${id}?search=${searchTerm}&page=${pageNumberBets + 1}&page_size=${pageSizeBets}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data);
                setEvents(response.data.event);
                setTotalBets(response.data.total);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoadingBets(false);
                setSearchLoading(false);
            });
    }, [id, token, searchTerm, pageNumberBets, pageSizeBets]);

    const sortedData = [...data].sort((a, b) => {
        return statusOrder[a.winning_bets_status] - statusOrder[b.winning_bets_status];
    });


    function confirmAddData(event) {
        event.preventDefault();
        setModifying(true);

        const formData = new FormData(event.target);

        // Prepare data for submission
        const data = {
            bet_number: formData.get("bet_number"),
            bet_wagered: formData.get("bet_wagered"),
        };

        setFormData(data);
        setAddConfirmModal(true);
    }

    const handleAddData = () => {
        setIsLoadingModalOne(true);
        if (isButtonDisabled) return; // Prevent further execution if button is already disabled
        setButtonDisabled(true); // Disable button immediately

        axios.post(`${process.env.REACT_APP_API_URL}/add/bet/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message);
                setPrintBet(response.data.print_bet);
                setShouldPrint(true);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                    toast.error(error.response.data.error);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoadingModalOne(false);
                }, 300); // 1-second delay
                setAddConfirmModal(false);
                setModifying(false);
                setAddModal(false);
                setButtonDisabled(false); // Re-enable button after the process
            });
    };

    useEffect(() => {
        if (shouldPrint && printBet) {
            // Add a delay before printing to ensure data has propagated
            setTimeout(() => {
                if (printRef.current) {
                    const printContent = printRef.current.innerHTML;
                    const printWindow = window.open('', '_blank');
                    printWindow.document.open();
                    printWindow.document.write(`
                        <html>
                            <head>
                                <title>Print Receipt</title>
                            </head>
                            <body>${printContent}</body>
                        </html>
                    `);
                    printWindow.document.close();
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                    setShouldPrint(false); // Reset shouldPrint to avoid duplicate prints
                }
            }, 500); // 0.5-second delay
        }
    }, [shouldPrint, printBet]);

    function confirmRelease(event) {
        event.preventDefault();
        setModifying(true);
        const formData = new FormData(event.target);

        const data = {
            bet_id: formData.get("bet_id"),
        };
        setFormData(data);
        setShowModal(true);
        setBetId(parseInt(formData.get("bet_id")))
    }


    const handleRelease = () => {
        setIsLoadingModalOne(true);
        if (isButtonDisabled) return; // Prevent further execution if button is already disabled
        setButtonDisabled(true); // Disable button immediately

        axios.put(`${process.env.REACT_APP_API_URL}/update/releasing-status/${bet_id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message);
                setPrintClaimReceipt(response.data.print_bet);
                setShouldPrintClaim(true);
            })
            .catch(error => {
                if (error.response && (error.response.status === 400 || error.response.status === 500)) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoadingModalOne(false);
                }, 300); // 1-second delay
                setShowModal(false);
                setBetId(0);
                setButtonDisabled(false);
                setModifying(false)
                setReleaseModal(false)
            });
    };

    useEffect(() => {
        if (shouldPrintClaim && printClaimReceipt) {
            // Add a delay before printing to ensure data has propagated
            setTimeout(() => {
                if (printClaimRef.current) {
                    const printContent = printClaimRef.current.innerHTML;
                    const printWindow = window.open('', '_blank');
                    printWindow.document.open();
                    printWindow.document.write(`
                        <html>
                            <head>
                                <title>Print Claim Receipt</title>
                            </head>
                            <body>${printContent}</body>
                        </html>
                    `);
                    printWindow.document.close();
                    printWindow.focus();
                    printWindow.print();
                    printWindow.close();
                    setShouldPrintClaim(false); // Reset shouldPrintClaim to avoid duplicate prints
                }
            }, 500); // 0.5-second delay
        }
    }, [shouldPrintClaim, printClaimReceipt]);

    function handlePrintReceipt(id) {
        navigate(`/portal/receipt/print/${id}`);
    }

    function handlePrintReceiptClaim(id) {
        navigate(`/portal/receipt-claim/print/${id}`);
    }

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const convertToLocalTime = (utcDatetime) => {
        const date = new Date(utcDatetime);
        return date.toLocaleString();
    };

    const calculateTotal = (key) => {
        let total = 0;
        sortedData.forEach((data) => {
            // Check if the winning_bets_status is not "CANCELLED"
            if (data.winning_bets_status !== 'CANCELLED') {
                const value = data[key];
                if (value) {
                    // Parse the value and add it to the total
                    total += parseFloat(value.replace('₱', '').replace(/,/g, '') || 0);
                }
            }
        });
        return `₱ ${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const handleCancelBet = (id) => {
        // Set the bet ID and open the cancel modal
        setBetId(id);
        setShowCancelModal(true);
    };

    const handleConfirmCancelBet = () => {
        // Close the cancel modal
        setShowCancelModal(false);

        // Call the backend API to cancel the bet
        axios.put(`${process.env.REACT_APP_API_URL}/cancel/bet`, {
            bet_id: bet_id,
            password: cancelPassword // Send the password to the backend
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setCancelPassword(""); // Reset the password input
            });
    };

    useEffect(() => {
        let room1 = '';
        if (userRole === 3) {
            room1 = `${id}|${event_name}|${userId}`;
            socket.emit('join_room', room1);
        }
        const room2 = `${id}|${event_name}`;

        socket.emit('join_room', room2);
        socket.on('bet_added', (newData) => {
            setData((prevData) => [newData, ...prevData]);
        });
        socket.on('start_fight_status', (updatedData) => {
            setEvents(updatedData);
        });

        socket.on('fight_result_updated', (updatedData) => {
            setEvents(updatedData);
        });
        socket.on('fight_result_updated_teller', (updatedData) => {
            setData((prevData) => {
                return prevData.map((data) => {
                    const updatedItem = updatedData.find((item) => item.id === data.id);
                    return updatedItem ? updatedItem : data;
                });
            });
        });
        socket.on('bet_releasing_status_updated', (updatedData) => {
            setData((prevData) => prevData.map((data) => (data.id === updatedData.id ? updatedData : data)));
        });
        socket.on('bet_releasing_status_updated_cash_update_logs', (newData) => {
            setTransactions((prevData) => [newData, ...prevData]);
        });
        socket.on('bet_added_cash_logs', (newData) => {
            setTransactions((prevData) => [newData, ...prevData]);
        });
        socket.on('cancel_bet', (updatedData) => {
            setData((prevData) => prevData.map((data) => (data.id === updatedData.id ? updatedData : data)));
        });

        return () => {
            if (userRole === 3) {
                socket.emit('leave_room', room1);
            }
            socket.emit('leave_room', room2);
            socket.off('bet_added');
            socket.off('start_fight_status');
            socket.off('fight_result_updated');
            socket.off('fight_result_updated_teller');
            socket.off('bet_releasing_status_updated');
            socket.off('bet_releasing_status_updated_cash_update_logs');
            socket.off('bet_added_cash_logs');
            socket.off('cancel_bet');
        };
    }, [event_name, fight_number, id, userId, userRole]);


    if (isLoadingBets || isLoadingData || isLoadingResults) {
        return <Loading/>;
    }

    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">
                Bets of Event: {event_name}
            </h3>
            <button className="btn btn-primary mb-3 mx-4" onClick={handleGoBack}>
                Back
            </button>
            {userRole === 3 ? (
                <div className='mb-3 mx-4'>
                    <div className="row mb-3 mx-4">
                        <div className="col">
                            <div className="card shadow border-start-success py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-primary fw-semibold mb-2">
                                                <span>Teller Number</span>
                                            </div>
                                            <div className="text-dark fw-bold h5 mb-0">
                                                <span>{teller.teller_number}</span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card shadow border-start-success py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-primary fw-semibold mb-2">
                                                <span>Username</span>
                                            </div>
                                            <div className="text-dark fw-bold h5 mb-0">
                                                <span>{teller.username}</span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 mx-4">
                        <div className="col">
                            <div className="card shadow border-start-success py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-primary fw-semibold mb-2">
                                                <span>Event Name</span>
                                            </div>
                                            <div className="text-dark fw-bold h5 mb-0">
                                                <span>{events.event_name}</span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card shadow border-start-success py-2">
                                <div className="card-body">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col me-2">
                                            <div className="text-uppercase text-primary fw-semibold mb-2">
                                                <span>Event Type</span>
                                            </div>
                                            <div className="text-dark fw-bold h5 mb-0">
                                                <span>{events.event_type}</span>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className=' mb-3 mx-4 text-center'>
                <button className="btn btn-warning btn-lg mb-3 mx-4" onClick={() => {
                    setReleaseModal(true);
                }}>
                    Release
                </button>
            </div>
            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Bets Information of Event: {event_name}</p>
                    {events.start_status === 0 && (
                        <button className="btn btn-primary text-end float-end btn-sm mx-1" onClick={() => {
                            setAddModal(true);
                        }}>
                            Add New Bet
                        </button>
                    )}
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className='col-md'>
                            <input type="text" className="form-control" placeholder="Search Anything Here!"
                                   aria-label="Search"
                                   aria-describedby="basic-addon2" value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)}/>
                        </div>
                        <div className='col-md-2'>
                            <select className="form-control" value={pageSizeBets} onChange={e => {
                                setPageSizeBets(Number(e.target.value));
                                setPageNumberBets(0); // Reset the page number when the page size changes
                            }}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                    </div>
                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>UUID</th>
                                <th>Bet Number</th>
                                <th>Bet Wagered</th>
                                <th>Bet Amount After Win</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {searchLoading ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                sortedData.length === 0 ? (
                                    <tr>
                                        <td colSpan="6" className="text-center"><strong>No results found.</strong>
                                        </td>
                                    </tr>
                                ) : (
                                    sortedData.map((data) => (
                                        <tr key={data.id}>
                                            <td>{data.id}</td>
                                            <td>{data.bet_number}</td>
                                            <td>{data.bet_wagered}</td>
                                            <td>{data.bet_amount_after_win}</td>
                                            <td>{data.winning_bets_status}</td>
                                            <td>
                                                <button className="btn btn-success btn-sm mx-1 my-1"
                                                        onClick={() => handlePrintReceipt(data.id)}>
                                                    <i className="fas fa-print"></i></button>
                                                <button className="btn btn-warning btn-sm mx-1 my-1"
                                                        onClick={() => handlePrintReceiptClaim(data.id)}>
                                                    <i className="fas fa-print"></i></button>
                                                {events.start_status === 0 && (
                                                    <button className="btn btn-danger btn-sm mx-1 my-1"
                                                            onClick={() => handleCancelBet(data.id)}>
                                                        <i className="fas fa-window-close"></i></button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )
                            )}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan="2">TOTAL</td>
                                <td>{calculateTotal('bet_wagered')}</td>
                                <td>{calculateTotal('bet_amount_after_win')}</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCountBets}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChangeBets}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <div className="card shadow border-primary mb-3 mx-4">
                <div className="card-header">
                    <p className="text-primary m-0 fw-bold d-inline">Cash Transaction Logs Information</p>
                </div>
                <div className="row g-3">
                    <div className='col-md'>
                        <select className="form-control" value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                            setPageNumber(0); // Reset the page number when the page size changes
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive table mt-2" id="dataTable" role="grid"
                         aria-describedby="dataTable_info">
                        <table className="table my-0" id="dataTable">
                            <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Transaction Type</th>
                                <th>Teller Number</th>
                                <th>Username</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                            {transactions.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center"><strong>No results found.</strong></td>
                                </tr>
                            ) : (
                                transactions.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.amount}</td>
                                        <td>{data.transaction_type}</td>
                                        <td>{data.teller_number}</td>
                                        <td>{data.teller.username}</td>
                                        <td>{convertToLocalTime(data.created_date)}</td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        containerClassName="pagination justify-content-center mt-3"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        breakClassName="page-item"
                        disabledClassName="disabled"
                    />
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add New Bet
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">Bet Number</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={0}
                            name="bet_number"
                            id="bet_number"
                            required
                            allowLeadingZeros={true}  // Allow leading zeros like "01"
                            isAllowed={(values) => {
                                const {formattedValue} = values;
                                const maxDigits = events.event_type === "LASTTWO" ? 2 : 3;  // Set max digits based on event_type
                                return formattedValue.length <= maxDigits;
                            }}
                        />
                        <label className="form-label mt-4">Bet Wagered</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={true}
                            allowNegative={false}
                            decimalScale={2}
                            name="bet_wagered"
                            id="bet_wagered"
                            required
                        />
                        {events.start_status === 0 && (
                            <div className="align-content-end">
                                <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                                >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                                </button>
                            </div>
                        )}
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                {isLoadingModalOne ? (
                    <div className="spinner-overlay">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <Modal.Header>
                            <Modal.Title>Confirm Bet Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p><strong>Bet Number:</strong> {formData.bet_number}</p>
                            <p><strong>Bet Wagered:</strong> {formData.bet_wagered}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setAddConfirmModal(false);
                                setModifying(false);
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleAddData} disabled={isButtonDisabled}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>

            <Modal
                size="lg"
                show={showReleaseModal}
                onHide={() => setReleaseModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Release Bets
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmRelease}>
                        <label className="form-label">
                            Bet UUID
                        </label>
                        <input className="form-control" type="text" name="bet_id" id="bet_id"
                               required/>
                        <div className="text-center">
                            <button className="btn btn-primary btn-lg mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Release"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                {isLoadingModalOne ? (
                    <div className="spinner-overlay">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Releasing Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to release the winnings? Please double check their receipt.
                            <br/>
                            <p><strong>Bet UUID:</strong> {bet_id}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setShowModal(false);
                                setModifying(false);
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleRelease} disabled={isButtonDisabled}>
                                Release
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>

            {/* Cancel Bet Modal */}
            <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Bet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To cancel the bet, please enter your password:</p>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                        value={cancelPassword}
                        onChange={(e) => setCancelPassword(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleConfirmCancelBet} disabled={!cancelPassword}>
                        Confirm Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div style={{display: 'none'}}>
                <Receipt ref={printRef} data={printBet}/>
            </div>
            <div style={{display: 'none'}}>
                <Claim ref={printClaimRef} data={printClaimReceipt}/>
            </div>
        </>
    );
}

export default TellerEvent;
