import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {socket} from "../others/SocketInstance";
import Loading from "../others/Loading";
import {Button, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {NumericFormat} from "react-number-format";

function AdminEvent() {
    const {id} = useParams();
    const {event_name} = useParams();
    const token = localStorage.getItem("token");
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [formData, setFormData] = useState({});
    const [showAddModal, setAddModal] = useState(false);
    const [showAddConfrimModal, setAddConfirmModal] = useState(false);
    const [isModifying, setModifying] = useState(false);
    const [isLoadingModalOne, setIsLoadingModalOne] = useState(false);
    const [showStartFightModal, setShowStartFightModal] = useState(false);
    const [showEditModal, setEditModal] = useState(false);
    const [showEditConfrimModal, setEditConfirmModal] = useState(false);

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    const userRole = getUserRole(token);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/event/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setData(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [token, id])

    const handleStartFightButtonClick = () => {
        setShowStartFightModal(true);
    };

    const handleStartFightButtonConfirm = () => {
        setIsLoadingModalOne(true);
        axios.put(`${process.env.REACT_APP_API_URL}/update/start-status/${id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoadingModalOne(false);
                }, 300); // 1-second delay
                setShowStartFightModal(false)

            })
    };

    function confirmAddData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            winning_combination: formData.get("winning_combination"),
        };
        setFormData(data);
        setAddConfirmModal(true);
    }

    function handleAddData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/event-result/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setAddModal(false)
            })
    }

    function confirmEditData(event) {
        event.preventDefault()
        setModifying(true)

        const formData = new FormData(event.target);

        const data = {
            winning_combination: formData.get("winning_combination"),
            password: formData.get("password"),
        };
        setFormData(data);
        setEditConfirmModal(true);
    }

    function handleEditData() {
        axios.put(`${process.env.REACT_APP_API_URL}/update/event-result-password/${id}`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    toast.error(error.response.data.message);
                } else {
                    console.log(error);
                    toast.error('Something went wrong. Please try again.');
                }
            })
            .finally(() => {
                setModifying(false)
                setEditModal(false)
            })
    }


    useEffect(() => {
        const room = `${id}|${event_name}`
        socket.emit('join_room', room);
        socket.on('start_fight_status', (updatedData) => {
            setData(updatedData);
        });
        socket.on('fight_result_updated', (updatedData) => {
            setData(updatedData);
        });
        return () => {
            socket.emit('leave_room', room);
            socket.off('start_fight_status');
            socket.off('fight_result_updated');
        };
    }, [id]);


    if (isLoading) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <h3 className="text-white mb-3 mt-3 mx-4 bg-gradient-primary pt-4 pb-4 px-4">Information
                of Event: {event_name}</h3>
            {userRole === 1 || userRole === 2 ? (
                <button className="mx-4 mb-5 btn btn-warning btn-lg " onClick={() => {
                    setFormData({
                        winning_combination: data.winning_combination,
                    });
                    setEditModal(true)
                }}><i className='fas fa-edit'></i></button>
            ) : null}
            <div className='mb-3 mx-4'>
                <div className="row mb-3 mx-4">
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Event Name & Status</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>{data.event_name} | {data.start_status === 1 ? "Started" : "Not Yet Started"}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Event Type</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>{data.event_type}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 mx-4">
                    <div className="col">
                        <div className="card shadow border-start-success py-2">
                            <div className="card-body">
                                <div className="row align-items-center no-gutters">
                                    <div className="col me-2">
                                        <div className="text-uppercase text-primary fw-semibold mb-2">
                                            <span>Winning Combination</span>
                                        </div>
                                        <div className="text-dark fw-bold h5 mb-0">
                                            <span>{data.winning_combination ? data.winning_combination : "NO DRAWS YET"}</span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-3 mx-4'>
                <div className='row'>
                    <div className="col">
                        <div className="text-end">
                            {userRole === 1 || userRole === 2 ? (
                                <button
                                    className='btn btn-danger btn-lg mt-2'
                                    onClick={handleStartFightButtonClick}
                                    disabled={data.start_status === 1}>
                                    CLOSE BETS
                                </button>
                            ) : null}
                        </div>


                    </div>
                    <div className="col">
                        <div className="text-start">
                            {userRole === 1 || userRole === 2 ? (
                                <button
                                    className='btn btn-success btn-lg mt-2'
                                    disabled={data.start_status === 0 || Boolean(data.winning_combination)}
                                    onClick={() => setAddModal(true)}>
                                    INPUT WINNING COMBINATION
                                </button>
                            ) : null}
                        </div>

                    </div>

                </div>
            </div>

            <Modal
                size="lg"
                show={showAddModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Input Winning Combination
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmAddData}>
                        <label className="form-label">Winning Combination</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={0}
                            name="winning_combination"
                            id="winning_combination"
                            required
                            allowLeadingZeros={true}  // Allow leading zeros like "01"
                            isAllowed={(values) => {
                                const {formattedValue} = values;
                                const maxDigits = data.event_type === "LASTTWO" ? 2 : 3;  // Set max digits based on event_type
                                return formattedValue.length <= maxDigits;
                            }}
                        />
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Add"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddConfrimModal} onHide={() => setAddConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Winning Combination</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Winning Combination:</strong> {formData.winning_combination}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setAddConfirmModal(false);
                        setModifying(false);
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setAddConfirmModal(false);
                        handleAddData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStartFightModal} onHide={() => setShowStartFightModal(false)} centered>
                {isLoadingModalOne ? (
                    <div className="spinner-overlay">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (<>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation for Close Bet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to Close Bet? It will close the betting.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowStartFightModal(false)}>No</Button>
                        <Button variant="primary" onClick={handleStartFightButtonConfirm}>Yes</Button>
                    </Modal.Footer>
                </>)}
            </Modal>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Winning Combination
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={confirmEditData}>
                        <label className="form-label">Winning Combination</label>
                        <NumericFormat
                            className="form-control"
                            thousandSeparator={false}
                            allowNegative={false}
                            decimalScale={0}
                            name="winning_combination"
                            id="winning_combination"
                            value={formData.winning_combination}
                            onChange={(e) => setFormData({...formData, winning_combination: e.target.value})}
                            required
                            allowLeadingZeros={true}  // Allow leading zeros like "01"
                            isAllowed={(values) => {
                                const {formattedValue} = values;
                                const maxDigits = data.event_type === "LASTTWO" ? 2 : 3;  // Set max digits based on event_type
                                return formattedValue.length <= maxDigits;
                            }}
                        />
                        <label className="form-label">Password</label>
                        <input className="form-control" type="password" name="password" id="password"/>
                        <div className="align-content-end">
                            <button className="btn btn-primary float-end mt-3" disabled={isModifying}
                            >{isModifying ? <i className="fa fa-spinner fa-spin"></i> : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showEditConfrimModal} onHide={() => setEditConfirmModal(false)} backdrop='static'>
                <Modal.Header>
                    <Modal.Title>Confirm Edit Winning Combination Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{color: 'red'}}><strong>Updating the winning combination may cause an update on the bets
                        of the winnings.
                        Make sure there are no released bets on this Event!</strong></p>
                    <p><strong>Winning Combination:</strong> {formData.winning_combination}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setEditConfirmModal(false);
                        setModifying(false);
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setEditConfirmModal(false);
                        handleEditData();
                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default AdminEvent;