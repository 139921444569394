import {Link, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import React from "react";

function Header() {
     const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    function getUserRole(token) {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.sub.account_type;
    }

    const userRole = getUserRole(token);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/login");
        toast.success('Logged out Successfully')
    };

    const isActiveRoute = (route) => {
        return location.pathname === route;
    };

    return (
        <>
            <nav
                className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
                <div className="container-fluid d-flex flex-column p-0"><Link
                    className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
                    to='/portal/dashboard'
                >
                    <div className="sidebar-brand-text mx-2"><span>STL SYSTEM</span></div>
                </Link>
                    <hr className="sidebar-divider my-0"/>
                    <ul className="navbar-nav text-light" id="accordionSidebar">
                        <li className='nav-item'><Link to='/portal/dashboard'
                                                       className={`nav-link${isActiveRoute('/portal/dashboard') ? ' active' : ''}`}>
                            <i className="fas fa-tachometer-alt"></i><span>Dashboard</span></Link></li>
                        <hr></hr>
                        <li className='nav-item'><Link to='/portal/events'
                                                       className={`nav-link${isActiveRoute('/portal/events') ? ' active' : ''}`}>
                            <i className="fas fa-tachometer-alt"></i><span>Events</span></Link></li>
                        <hr></hr>
                        {userRole === 1 || userRole === 4 ? (
                            <>
                                <li className='nav-item'><Link to='/portal/settings'
                                                               className={`nav-link${isActiveRoute('/portal/settings') ? ' active' : ''}`}>
                                    <i className="fas fa-tachometer-alt"></i><span>Settings</span></Link></li>
                                <hr></hr>
                            </>
                        ) : null}
                        <li className="nav-item"><Link className="nav-link" to='/' onClick={handleLogout}><i
                            className="fas fa-sign-out-alt"></i><span>Logout</span></Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Header;