import React, {forwardRef} from 'react';
import Barcode from 'react-barcode';

const Claim = forwardRef((props, ref) => {
     const convertToLocalTime = (utcDatetime) => {
        const date = new Date(utcDatetime);
        return date.toLocaleString();
    };

    const {data} = props; // Destructure data from props
    return (
        <div ref={ref}>
            <div style={{width: '400px', maxWidth: '400px'}}>
                <div style={{textAlign: 'center'}}>
                    <h2>SMALL TOWN LOTTERY SYSTEM</h2>
                    <h2>Gr8Minds Maximus</h2>
                    <h3>{data?.event?.event_name}</h3>
                    <h3>{convertToLocalTime(data?.updated_date)}</h3>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>Winning Combination: {data?.event?.winning_combination}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>Bet ID: {data?.id}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>Bet Number: {data?.bet_number}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>Bet Wagered: {data?.bet_wagered}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>Payout: {data?.bet_amount_after_win}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>TELLER # {data?.teller?.teller_number}</h3>
                    </div>
                    <div style={{marginTop: '16px', textAlign: 'left'}}>
                        <h3>TELLER: {data?.teller?.username}</h3>
                    </div>

                </div>
            </div>
        </div>
    )
});

export default Claim;